import { StoreActions } from "./StoreActions.enum";
import { StoreModel } from "./StoreModel.interface";

export class StoreActionPayload implements Record<StoreActions, unknown> {
  /** List of services */
  [StoreActions.FETCH_SERVICES]: StoreModel['services']['items'];

  /** ID of service */
  [StoreActions.ADD_SERVICE_TO_BASKET]: number;

  /** ID of basket element */
  [StoreActions.REMOVE_SERVICE_FROM_BASKET]: number;

  /** Update info for order */
  [StoreActions.UPDATE_INFO_FOR_ORDER]: StoreModel['basket']['info'];

  /** Set status of create order after request */
  [StoreActions.SET_ORDER_RESULT]: {
    status: 'success' | 'failed';
    orderId?: string;
  };

  /** Reset state of app after success order */
  [StoreActions.RESET_AFTER_ORDER]: null;
}
