import { StoreModel } from "data/store/model";
import { StoreInLocalStorage } from "./model";

/** Save and load part of state in local storage of browser */
export class LocalStorageMiddleware {
  private static readonly STORE_CACHE_KEY = 'saved_store';

  private static readonly INITIAL_BASKET = {
    basket: {
      items: [],
      info: {},
    },
  };

  static save = ({ basket }: StoreModel) => {
    localStorage.setItem(
      LocalStorageMiddleware.STORE_CACHE_KEY,
      JSON.stringify({ basket } as StoreInLocalStorage),
    );
  };

  static load = (): Partial<StoreModel> => {
    const savedInfo: StoreInLocalStorage = JSON.parse(
      localStorage.getItem(LocalStorageMiddleware.STORE_CACHE_KEY) ??
        JSON.stringify(LocalStorageMiddleware.INITIAL_BASKET)
    );

    return {
      basket: savedInfo.basket,
    };
  };
}
