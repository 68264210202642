import { CatalogApi } from "data/api";
import { StoreActions } from "./model";
import { StoreActionDispatch } from "./model/StoreActionDispatch.type";
import { StoreActionPayload } from "./model/StoreActionPayload";

/** Proxy for easy call of dispatches in stores */
export class StoreActionSupervisor {
  static fetchServices = async (dispatch: StoreActionDispatch<StoreActions.FETCH_SERVICES>) => {
    const services = await CatalogApi.getServices();

    return dispatch({
      type: StoreActions.FETCH_SERVICES,
      payload: services,
    });
  };

  static addServiceToBasket = (
    dispatch: StoreActionDispatch<StoreActions.ADD_SERVICE_TO_BASKET>, payload: number) => {
    return dispatch({
      type: StoreActions.ADD_SERVICE_TO_BASKET,
      payload,
    });
  };

  static removeServiceFromBasket = (
    dispatch: StoreActionDispatch<StoreActions.REMOVE_SERVICE_FROM_BASKET>,
    basketElementId: number,
  ) => dispatch({
    type: StoreActions.REMOVE_SERVICE_FROM_BASKET,
    payload: basketElementId,
  });

  static updateInfoForOrder = (
    dispatch: StoreActionDispatch<StoreActions.UPDATE_INFO_FOR_ORDER>,
    payload: StoreActionPayload[StoreActions.UPDATE_INFO_FOR_ORDER],
  ) => dispatch({
    type: StoreActions.UPDATE_INFO_FOR_ORDER,
    payload,
  });

  static setOrderResult = (
    dispatch: StoreActionDispatch<StoreActions.SET_ORDER_RESULT>,
    payload: StoreActionPayload[StoreActions.SET_ORDER_RESULT],
  ) => dispatch({
    type: StoreActions.SET_ORDER_RESULT,
    payload,
  });

  static resetAppAfterSuccessOrder = (dispatch: StoreActionDispatch<StoreActions.RESET_AFTER_ORDER>) =>
    dispatch({
      type: StoreActions.RESET_AFTER_ORDER,
      payload: null,
    });
}
