import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: ${props => props.theme.grid.maxWidth};
  margin: 0 auto;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;