import { Button, Result, ResultProps } from "antd";
import { Store, StoreActionSupervisor, StoreModel, StoreTransformer } from "data/store";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { AcquiringButton } from "./AcquiringButton/AcquiringButton";

const OrderResultView = styled(Result)`
  @media (max-width: 610px) {
    padding: 24px 0;

    .ant-result-title {
      font-size: 16px;
    }
  }

  @media (max-width: 380px) {
    .ant-result-title {
      font-size: 14px;
    }
  }
`;

interface AfterOrderResult {
  status: StoreModel['orderResult']['status'];
  orderId: StoreModel['orderResult']['orderId'];
  amount: number;
  orderData: string;
}

export const OrderPay = () => {
  const { state, dispatch } = useContext(Store);
  const [result, setResult] = useState<AfterOrderResult | null>(null);

  const getStatus = (): ResultProps['status'] => {
    switch (result?.status) {
      case 'success':
        return 'success';
      default:
        return 'warning';
    }
  };

  const getTitle = (): string => {
    switch (result?.status) {
      case 'success':
        return 'Заказ успешно оптравлен! Через несколько секунд вы будете перенаправлены на страницу оплаты. Если вы не оплатите заказ сейчас, его можно будет оплатить при посещении врача.';
      default:
        return 'Сессия заказа истекла';
    }
  };

  useEffect(() => {
    if (state.orderResult.status === 'success') {
      setResult({
        status: state.orderResult.status,
        orderId: state.orderResult.orderId,
        amount: StoreTransformer.getSummaryOrder(state),
        orderData: StoreTransformer.getOrderData(state),
      });
      StoreActionSupervisor.resetAppAfterSuccessOrder(dispatch);
    }
  }, [state.orderResult, dispatch, state]);

  return (
    <OrderResultView
      status={getStatus()}
      title={getTitle()}
      extra={
        result === null
          ? (
              <Link to="/order">
                <Button type="primary">Перейти в корзину</Button>
              </Link>
            )
          : <AcquiringButton orderId={result.orderId!} amount={result.amount} orderData={result.orderData} />
      }
    />
  );
};
