import { StoreModel, StoreAction, StoreActions, StoreActionPayload } from "./model";

export const reducer = (
  state: StoreModel,
  action: StoreAction<StoreActions>,
): StoreModel => {
  switch (action.type) {
    case StoreActions.FETCH_SERVICES: {
      return {
        ...state,
        services: {
          loading: false,
          items: action.payload as StoreActionPayload[StoreActions.FETCH_SERVICES]
        },
      };
    }

    case StoreActions.ADD_SERVICE_TO_BASKET: {
      return {
        ...state,
        basket: {
          ...state.basket,
          items: [
            ...state.basket.items,
            {
              serviceId: (action as StoreAction<StoreActions.ADD_SERVICE_TO_BASKET>).payload,
              id: (state.basket.items[state.basket.items.length - 1]?.id ?? 0) + 1,
            },
          ],
        },
      };
    }

    case StoreActions.REMOVE_SERVICE_FROM_BASKET: {
      return {
        ...state,
        basket: {
          ...state.basket,
          items: state.basket.items.filter(
            ({ id }) => id !== (action as StoreAction<StoreActions.REMOVE_SERVICE_FROM_BASKET>).payload
          ),
        },
      };
    }

    case StoreActions.UPDATE_INFO_FOR_ORDER:
      return {
        ...state,
        basket: {
          ...state.basket,
          info: {
            ...state.basket.info,
            ...(action as StoreAction<StoreActions.UPDATE_INFO_FOR_ORDER>).payload,
          },
        },
      };

    case StoreActions.SET_ORDER_RESULT:
      return {
        ...state,
        orderResult: (action as StoreAction<StoreActions.SET_ORDER_RESULT>).payload,
      };

    case StoreActions.RESET_AFTER_ORDER: {
      return {
        ...state,
        basket: {
          items: [],
          info: {},
        },
        orderResult: { status: null },
      };
    }

    default:
      return state;
  }
};
