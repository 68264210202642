export enum StoreActions {
  /** Fetch services from backend */
  FETCH_SERVICES = 'FETCH_SERVICES',

  /** Add service to basket (add new or increment existing) */
  ADD_SERVICE_TO_BASKET = 'ADD_SERVICE_TO_BASKET',

  /** Remove service from basket (remove single or decrement) */
  REMOVE_SERVICE_FROM_BASKET = 'REMOVE_SERVICE_FROM_BASKET',

  /** Update info from order form */
  UPDATE_INFO_FOR_ORDER = 'UPDATE_INFO_FOR_ORDER',

  /** Set order result after response from backend */
  SET_ORDER_RESULT = 'SET_ORDER_RESULT',

  /** Reset basket after order */
  RESET_AFTER_ORDER = 'RESET_AFTER_ORDER',
}
