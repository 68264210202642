import { Button } from "antd";
import { AcquiringApi } from "data/api";
import { FC, useCallback, useEffect, useState } from "react";

interface Props {
  orderId: string;
  amount: number;
  orderData: string;
}

export const AcquiringButton: FC<Props> = ({ orderId, amount, orderData }) => {
  const [isLinkProcessing, setLinkProcessing] = useState(false);
  const [acquiringLink, setAcquiringLink] = useState<string>('');

  // eslint-disable-next-line no-restricted-globals
  const goToAcquiring = useCallback((link: string) => location.assign(link), []);

  useEffect(() => {
    if (!isLinkProcessing && !acquiringLink) {
      setLinkProcessing(true);

      AcquiringApi.initPay({ amount, orderId, orderData }).then(({ PaymentURL }) => {
        if (PaymentURL) {
          setAcquiringLink(PaymentURL);
          setLinkProcessing(false);

          setTimeout(() => goToAcquiring(PaymentURL), 5000);
        }
      });
    }
  }, [acquiringLink, amount, goToAcquiring, isLinkProcessing, orderData, orderId]);

  return <Button type="primary" loading={isLinkProcessing} onClick={() => goToAcquiring(acquiringLink)}>Перейти к оплате</Button>;
};
