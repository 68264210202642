import { Button, Result, ResultProps } from "antd";
import { Store, StoreActionSupervisor, StoreModel } from "data/store";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const OrderResultView = styled(Result)`
  @media (max-width: 610px) {
    padding: 24px 0;

    .ant-result-title {
      font-size: 16px;
    }
  }

  @media (max-width: 380px) {
    .ant-result-title {
      font-size: 14px;
    }
  }
`;

export const OrderResult = () => {
  const { state, dispatch } = useContext(Store);
  const [result, setResult] = useState<StoreModel['orderResult']['status']>(null);

  const getStatus = (): ResultProps['status'] => {
    switch (result) {
      case 'success':
        return 'success';
      case 'failed':
        return 'error';
      default:
        return 'warning';
    }
  };

  const getTitle = (): string => {
    switch (result) {
      case 'success':
        return 'Заказ успешно оптравлен! В скором времени с вами свяжется менеджер для подтверждения заказа.';
      case 'failed':
        return 'Произошла ошибка при создании заказа. Ваша корзина и данные не очистились. Попробуйте ещё раз. При повторении ошибки, пожалуйста, свяжитесь с нами.';
      default:
        return 'Сессия заказа истекла';
    }
  };

  useEffect(() => {
    if (state.orderResult.status !== null) {
      setResult(state.orderResult.status);

      if (state.orderResult.status === 'success') {
        StoreActionSupervisor.resetAppAfterSuccessOrder(dispatch);
      }
    }
  }, [state.orderResult.status, dispatch]);

  return (
    <OrderResultView
      status={getStatus()}
      title={getTitle()}
      extra={
        state.orderResult.status === 'failed'
          ? (
              <Link to="/order">
                <Button type="primary">Перейти в корзину</Button>
              </Link>
            )
          : (
              <Link to="/">
                <Button type="primary">Перейти в услуги</Button>
              </Link>
            )
      }
    />
  );
};
