import { LoadingOutlined } from "@ant-design/icons";
import { Spin, message, List, Input, Space } from "antd";
import { useContext, useMemo, useState } from "react";
import { Store, StoreActionSupervisor } from "data/store";
import styled from "styled-components";
import { Service } from "./Service/Service";

const Preloader = styled(LoadingOutlined)`
  color: ${props => props.theme.colors.back};
  font-size: 48px;
`;

const PreloaderArea = styled(Spin)`
  min-height: calc(100vh - 200px);
`;

export const Services = () => {
  const { state, dispatch } = useContext(Store);
  const [searchRequest, setSearchRequest] = useState<string>('');

  const serviceItems = useMemo(
    () =>
      searchRequest
        ? state.services.items.filter(
            (service) =>
              service?.desc?.toLowerCase().includes(searchRequest)
              || service?.name?.toLowerCase().includes(searchRequest)
              || service?.documentId?.toLowerCase().includes(searchRequest)
          )
        : state.services.items,
    [state, searchRequest]
  );

  const addServiceToBasket = (id: number) => {
    StoreActionSupervisor.addServiceToBasket(dispatch, id);
    message.success('Услуга добавлена в корзину');
  };

  const removeServiceFromBasket = (id: number) => {
    const serviceInBasketId = state.basket.items.find(({ serviceId }) => serviceId === id)?.id;

    if (serviceInBasketId) {
      StoreActionSupervisor.removeServiceFromBasket(dispatch, serviceInBasketId);
      message.success('Услуга удалена из корзины');
    }
  };

  return (
    <PreloaderArea spinning={state.services.loading} indicator={<Preloader />}>
      <div style={{ background: '#fff', padding: '20px', borderRadius: '5px' }}>
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Input.Search
            placeholder="Найти услугу..."
            allowClear
            onSearch={(e) => setSearchRequest(e?.toLowerCase() ?? '')}
          />

          <List
            itemLayout="horizontal"
            dataSource={serviceItems}
            renderItem={service => {
              const isServiceInBasket = state.basket.items.some(
                ({ serviceId }) => serviceId === service.id
              );

              return (
                <Service
                  name={service.name}
                  cost={service.cost}
                  isServiceInBasket={isServiceInBasket}
                  onAdd={() => addServiceToBasket(service.id)}
                  onRemove={() => removeServiceFromBasket(service.id)}
                  desc={service.desc}
                  documentId={service.documentId}
                />
              );
            }}
          />
        </Space>
      </div>
    </PreloaderArea>
  );
}
