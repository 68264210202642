import { ShoppingOutlined } from "@ant-design/icons";
import { Badge, Button, Divider, Menu } from "antd";
import { logo } from "assets/images/logo";
import { Wrapper } from "common/components";
import { Store, StoreTransformer } from "data/store";
import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Logo = styled.div`
  background: url(${logo});
  background-size: contain;
  height: 40px;
  width: 125px;
  margin-right: 20px;

  @media (max-width: 500px) {
    background-size: auto 100%;
    width: 50px;
  }
`;

const LeftPart = styled.div`
  display: flex;
  align-items: center;
`;

const BasketIcon = styled(ShoppingOutlined)`
  font-size: 24px;
`;

export const Header = () => {
  const { state } = useContext(Store);

  return (
    <Wrapper>
      <LeftPart>
        <Link to="/"><Logo /></Link>
  
        <Divider type="vertical" />
  
        <Menu mode="horizontal" selectable={false}>
          <Menu.Item>
            <Link to="/">Каталог</Link>
          </Menu.Item>
  
          <Menu.Item>
            <a href="http://zdorovie38.ru/services" target="_blank" rel="noreferrer">Услуги</a>
          </Menu.Item>
  
          <Menu.Item>
            <a href="http://zdorovie38.ru/team" target="_blank" rel="noreferrer">Специалисты</a>
          </Menu.Item>
  
          <Menu.Item>
            <a href="http://zdorovie38.ru/sales" target="_blank" rel="noreferrer">Акции</a>
          </Menu.Item>
  
          <Menu.Item>
            <a href="http://zdorovie38.ru/photo" target="_blank" rel="noreferrer">Фотогалерея</a>
          </Menu.Item>
  
          <Menu.Item>
            <a href="http://zdorovie38.ru/reviews" target="_blank" rel="noreferrer">Отзывы</a>
          </Menu.Item>
  
          <Menu.Item>
            <a href="http://zdorovie38.ru/blog" target="_blank" rel="noreferrer">Блог</a>
          </Menu.Item>
        </Menu>
      </LeftPart>
  
      <Link to="/order">
        <Button type="text">
          <Badge count={StoreTransformer.getItemCountInBasket(state)}>
            <BasketIcon />
          </Badge>
        </Button>
      </Link>
    </Wrapper>
  );
};