import { Button, Result, Steps } from "antd";
import { useMobileWidth } from "common/hooks";
import { Store, StoreTransformer } from "data/store";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BasketList } from "./BasketList/BasketList";
import { OrderConfirm } from "./OrderConfirm/OrderConfirm";
import { WrappedOrderForm } from "./OrderForm/OrderForm";

const OrderStepsWrapper = styled(Steps)`
  margin-bottom: 30px;
`;

export const OrderSteps = () => {
  const [step, changeStep] = useState<number>(0);
  const { state } = useContext(Store);
  const isMobile = useMobileWidth();

  return StoreTransformer.getItemCountInBasket(state) === 0
    ? (<Result
        status="404"
        title="В вашей корзине пока ничего нет"
        subTitle="Перейдите в раздел услуг, выберите одну или несколько и добавьте в корзину"
        extra={<Link to="/"><Button type="primary">Посмотреть услуги</Button></Link>}
      />)
    : (<>
        <OrderStepsWrapper current={step} direction={isMobile ? 'vertical' : 'horizontal'}>
          <Steps.Step title="Корзина" />
          <Steps.Step title="Оформление заказа" />
          <Steps.Step title="Подтверждение и оплата" />
        </OrderStepsWrapper>

        {step === 0 ? <BasketList onNextStageClick={() => changeStep(1)} /> : <></>}

        {
          step === 1
            ? (
                <WrappedOrderForm
                  onPrevStageClick={() => changeStep(0)}
                  onNextStageClick={() => changeStep(2)}
                />
              )
            : <></>
        }

        {
          step === 2
            ? <OrderConfirm onPrevStageClick={() => changeStep(1)} />
            : <></>
        }
      </>);
};
