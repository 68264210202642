import { Typography } from "antd";
import { Wrapper } from "common/components";
import visa from 'assets/images/visa.svg';
import mastercard from 'assets/images/mastercard.svg';
import mir from 'assets/images/mir.svg';
import tinkoff from 'assets/images/tinkoff.svg';
import styled from "styled-components";

const Col = styled.div`
  width: 33%;

  @media (max-width: 855px) {
    width: 100%;

    && {
      text-align: center;
    }
  }
`;

const ColWithLogos = styled(Col)`
  text-align: right;

  @media (max-width: 855px) {
    margin-top: 40px;
  }
`;

const PolicyCol = styled(Col)`
  text-align: center;
`;

const PaySystemLogo = styled.img`
  display: inline-block;
  height: 24px;
  margin-right: 12px;

  &::last-of-type {
    margin-right: 0;
  }

  @media (max-width: 430px) {
    margin-bottom: 10px;
  }
`;

export const Footer = () => (
  <Wrapper>
    <Col>
      <Typography.Paragraph>
        г. Иркутск, ул. Лермонтова, 275/15<br />
        г. Иркутск, ул. Лермонтова, 275/19
      </Typography.Paragraph>

      <Typography.Paragraph>
        <Typography.Link href="tel:+79246023210">+7 (924) 602-32-10</Typography.Link>
      </Typography.Paragraph>
    </Col>

    <PolicyCol>
      <Typography.Link href="/files/policy.pdf" target="_blank">Политика конфиденциальности</Typography.Link>
    </PolicyCol>

    <ColWithLogos>
      <PaySystemLogo src={visa} alt="Visa" />
      <PaySystemLogo src={mastercard} alt="Mastercard" />
      <PaySystemLogo src={mir} alt="МИР" />
      <PaySystemLogo src={tinkoff} alt="Тинькофф" />
    </ColWithLogos>
  </Wrapper>
);
