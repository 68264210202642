import React, { PropsWithChildren, useEffect, useReducer } from "react";
import { DebugMiddleware, LocalStorageMiddleware } from "./middlewares";
import { StoreActions, StoreModel } from "./model";
import { StoreActionDispatch } from "./model/StoreActionDispatch.type";
import { reducer } from "./storeReducer";

let initialState: StoreModel = {
  services: {
    loading: true,
    items: [],
  },
  basket: {
    items: [],
    info: {},
  },
  orderResult: { status: null },
};

initialState = Object.assign(initialState, LocalStorageMiddleware.load());

export const Store = React.createContext<{
  state: StoreModel;
  dispatch: StoreActionDispatch<StoreActions>
}>({
  state: initialState,
  dispatch: () => {},
});

export const StoreProvider = (props: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  useEffect(() => {
    LocalStorageMiddleware.save(state);
    DebugMiddleware.output(state);
  });

  return <Store.Provider value={value}>{props.children}</Store.Provider>;
};
