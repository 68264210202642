import { Layout } from "antd";
import { useContext, useEffect } from "react";
import './styles.css';
import { Header } from "./Header/Header";
import { Services } from "./Services/Services";
import styled from "styled-components";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Order } from "./Order/Order";
import { Store, StoreActionSupervisor } from "data/store";
import { Footer } from "./Footer/Footer";

const StyledHeader = styled(Layout.Header)`
  background: ${props => props.theme.colors.back};
`; 

const StyledFooter = styled(Layout.Footer)`
  background: ${props => props.theme.colors.back};
`;

const StyledContent = styled(Layout.Content)`
  background: linear-gradient(to left, #00c9ff, #92fe9d);
  background-position: fixed;
`;

const Wrapper = styled.div`
  max-width: ${props => props.theme.grid.maxWidth};
  margin: 0 auto;
  padding: 50px 0;
  min-height: calc(100vh - 64px);

  @media (max-width: ${props => props.theme.grid.maxWidth}) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const App = () => {
  const { state, dispatch } = useContext(Store);

  useEffect(() => {
    if (state.services.loading) {
      StoreActionSupervisor.fetchServices(dispatch);
    }
  }, [state, dispatch]);

  return (
    <BrowserRouter>
      <Layout>
        <StyledHeader>
          <Header />
        </StyledHeader>

        <StyledContent>
          <Wrapper>
            <Switch>
              <Route path="/order">
                <Order />
              </Route>

              <Route path="/" exact>
                <Services />
              </Route>
            </Switch>
          </Wrapper>
        </StyledContent>

        <StyledFooter>
          <Footer />
        </StyledFooter>
      </Layout>
    </BrowserRouter>
  );
}
