import { Table, Typography } from "antd";
import { Store, StoreTransformer } from "data/store";
import { FC, useContext } from "react";

export const BillSummary: FC = () => {
  const { state } = useContext(Store);

  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0}>
        <Typography.Text strong>Итого:</Typography.Text>
      </Table.Summary.Cell>

      <Table.Summary.Cell index={1}>
        <Typography.Text strong>{StoreTransformer.getSummaryOrder(state)} ₽</Typography.Text>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};
