import { Route, Switch, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { OrderPay } from "./OrderPay/OrderPay";
import { OrderResult } from "./OrderResult/OrderResult";
import { OrderSteps } from "./OrderSteps/OrderSteps";

const OrderWrapper = styled.div`
  background: ${props => props.theme.colors.back};
  padding: 36px;
  border-radius: 6px;
`;

export const Order = () => {
  const { path } = useRouteMatch();

  return (
    <OrderWrapper>
      <Switch>
        <Route path={`${path}/result`}>
          <OrderResult />
        </Route>

        <Route path={`${path}/pay`}>
          <OrderPay />
        </Route>

        <Route exact path={path}>
          <OrderSteps />
        </Route>
      </Switch>
    </OrderWrapper>
  );
};
