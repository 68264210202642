import axios from "axios";
import { AcquiringInitPayParams, AcquiringInitPayResponse } from "data/model";

/** API to acquiring */
export class AcquiringApi {
  /** URL of Tinkoff acquiring */
  private static readonly ACQUIRING_ORIGIN = 'https://securepay.tinkoff.ru/v2';

  private static post = async <T, R>(url: string, params: T) => {
    const { data } = await axios.post(`${AcquiringApi.ACQUIRING_ORIGIN}/${url}`, params);
    return data as R;
  };

  /**
   * Init pay for new order and get link to Tinkoff payment form
   * @params - Params for new pay
   */
  static initPay = (params: { amount: number; orderId: string, orderData: string }) => AcquiringApi.post<AcquiringInitPayParams, AcquiringInitPayResponse>(
    'Init',
    {
      TerminalKey: '1620192036758',
      Amount: params.amount * 100,
      OrderId: params.orderId,
      SuccessURL: 'https://booking.zdorovie38.ru/',
      Description: params.orderData,
    },
  );
}
