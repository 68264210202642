import { Button, Col, Descriptions, Row, Space } from "antd";
import { useMobileWidth } from "common/hooks";
import { CatalogApi } from "data/api";
import { Store, StoreActionSupervisor, StoreTransformer } from "data/store";
import { FC, PropsWithChildren, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { ServicesList } from "./ServicesList/ServicesList";

interface OrderConfirmProps {
  onPrevStageClick: () => void;
}

export const OrderConfirm: FC<OrderConfirmProps> = (props: PropsWithChildren<OrderConfirmProps>) => {
  const { state, dispatch } = useContext(Store);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const isMobile = useMobileWidth(710);

  const services = state.basket.items.map(({ serviceId }) => {
    const service = state.services.items.find(({ id }) => id === serviceId);

    return {
      name: service?.name,
      cost: service?.cost,
    };
  });

  const summaryOrder = StoreTransformer.getSummaryOrder(state);

  const createOrder = () => {
    setSubmitting(true);

    CatalogApi.createOrder({
      service_in_order: state.basket.items.map(({ serviceId }) => ({
        service: serviceId,
      })),
      name: state.basket.info!.name!,
      surname: state.basket.info!.surname!,
      phone: state.basket.info!.phone!,
      email: state.basket.info!.email!,
      note: state.basket.info!.comment! ?? '',
      is_paid: false,
    })
      .then(({ id }) => {
        StoreActionSupervisor.setOrderResult(
          dispatch,
          {
            status: 'success',
            orderId: id,
          },
        );

        history.push('/order/pay');
      })
      .catch(() => {
        StoreActionSupervisor.setOrderResult(dispatch, { status: 'failed' });
        history.push('/order/result');
      });
  };

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Descriptions title="Детали заказа" bordered column={{ xxl: 6, xl: 6, lg: 6, md: 4, sm: 2, xs: 2 }} size="middle" layout={isMobile ? 'vertical' : 'horizontal'}>
        <Descriptions.Item label="Имя и Фамилия" span={2}>
          {`${state.basket.info?.name} ${state.basket.info?.surname}`}
        </Descriptions.Item>

        <Descriptions.Item label="Телефон" span={2}>{state.basket.info?.phone}</Descriptions.Item>
        <Descriptions.Item label="E-mail" span={2}>{state.basket.info?.email}</Descriptions.Item>
        <Descriptions.Item label="Комментарий" span={6}>{state.basket.info?.comment ?? '-'}</Descriptions.Item>

        <Descriptions.Item label="Выбранные услуги" span={6}>
          <ServicesList services={services} />
        </Descriptions.Item>

        <Descriptions.Item label="Итого" span={6}>{summaryOrder} ₽</Descriptions.Item>
      </Descriptions>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Button block onClick={props.onPrevStageClick}>Вернуться к оформлению заказа</Button>
        </Col>

        <Col xs={24} sm={12}>
          <Button block type="primary" loading={submitting} onClick={createOrder}>
            Подтвердить и оплатить заказ
          </Button>
        </Col>
      </Row>
    </Space>
  );
};
