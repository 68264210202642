import { CloseOutlined } from "@ant-design/icons";
import { Popconfirm, Button } from "antd";
import { FC } from "react";

export const DeleteServiceButton: FC<{ onConfirm?: () => void }> = props => (
  <Popconfirm
    title="Вы точно хотите удалить услугу из корзины?"
    okText="Да"
    cancelText="Нет"
    onConfirm={props.onConfirm}
  >
    <Button type="link">
      <CloseOutlined />
    </Button>
  </Popconfirm>
);
