import { ServiceDTO, OrderCreateParams } from "data/model";
import axios from 'axios';

export class CatalogApi {
  static readonly BACKEND_ORIGIN = 'https://zdorovie.sclimat.com';

  private static get = async <R = any, P = any>(url: string, params?: P): Promise<R> => {
    const { data } = await axios.get(`${CatalogApi.BACKEND_ORIGIN}/${url}`, { params });
    return data.data;
  };

  private static post = async <P = any, R = any>(url: string, params: P): Promise<R> => {
    const { data } = await axios.post(
      `${CatalogApi.BACKEND_ORIGIN}/${url}`,
      params,
    );

    return data.data;
  };

  /** Get all services of booking */
  static getServices = async () => CatalogApi.get<ServiceDTO[]>(
    'items/service',
    { limit: 1000 },
  );

  /**
   * Create new order
   * @params - Params for new order
   */
  static createOrder = async (params: OrderCreateParams) => CatalogApi.post<OrderCreateParams, { id: string }>(
    'items/order?fields[]=id',
    params,
  );
}
