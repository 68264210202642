import { List } from "antd";
import { FC } from "react";
import styled from "styled-components";

export interface ServiceInConfirm {
  name: string | undefined;
  cost: number | undefined;
}

interface Props {
  services: ServiceInConfirm[];
}

const ServiceInList = styled(List.Item)`
  @media (max-width: 410px) {
    flex-direction: column;
    align-items: normal;
    flex-wrap: nowrap;
  }
`;

export const ServicesList: FC<Props> = ({ services }) => (
  <List
    dataSource={services}
    itemLayout="horizontal"
    renderItem={item => (
      <ServiceInList>
        <List.Item.Meta title={item.name} />

        <span>{item.cost} ₽</span>
      </ServiceInList>
    )}
  />
);
