import { ConfigProvider } from 'antd';
import { defaultTheme } from 'assets/styles/defaultTheme';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { App } from './App/App';
import ruRU from 'antd/lib/locale/ru_RU';
import { StoreProvider } from 'data/store/Store';
import 'moment/locale/ru';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <StoreProvider>
        <ConfigProvider locale={ruRU}>
          <App />
        </ConfigProvider>
      </StoreProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
