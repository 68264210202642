import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
} from "antd";
import { Store, StoreActionSupervisor, StoreModel } from "data/store";
import React, { FC, useContext } from "react";
import MaskedInput from 'antd-mask-input';
import { BillSummary } from "./BillSummary/BillSummary";
import { Rule } from "antd/lib/form";

interface OrderFormProps {
  onPrevStageClick: () => void;
  onNextStageClick: () => void;
}

interface ServiceInfo {
  id: number;
  name: string;
  cost: number;
  serviceId: number;
}

const agreementValidator: Rule[] = [{
  validator: (_, value, callback) => {
    if (!value) {
      callback('Вы должны согласиться с условиями');
    } else {
      callback();
    }
  }
}];

const OrderForm: FC<OrderFormProps> = props => {
  const { state, dispatch } = useContext(Store);

  const data: ServiceInfo[] = state.basket.items.map(({ id, serviceId }) => {
    const service = state.services.items.find(service => service.id === serviceId);

    return {
      id,
      name: service?.name ?? '',
      cost: service?.cost ?? 0,
      serviceId,
    };
  });

  const submit = (value: StoreModel['basket']['info']) => {
    StoreActionSupervisor.updateInfoForOrder(dispatch, value);
    props.onNextStageClick();
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={{ ...state.basket.info }}
        onFinish={submit}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Table
            dataSource={data}
            pagination={false}
            summary={() => <BillSummary />}
            scroll={{ x: 830 }}
          >
            <Table.Column title="Услуга/процедура" dataIndex="name" key="name" />
            <Table.Column
              title="Стоимость"
              dataIndex="cost"
              key="cost"
              render={(cost: string) => `${cost} ₽`}
            />
          </Table>

          <div>
            <Form.Item label="Имя" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Фамилия" name="surname" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Телефон" name="phone" rules={[{ required: true }]}>
              <MaskedInput mask="+7 (111) 111-11-11" />
            </Form.Item>

            <Form.Item label="E-mail" name="email">
              <Input />
            </Form.Item>

            <Form.Item label="Комментарий" name="comment">
              <Input.TextArea rows={3} />
            </Form.Item>

            <Form.Item
              name="personalData"
              valuePropName="checked"
              rules={agreementValidator}
              wrapperCol={{ offset: 6, span: 18 }}
            >
              <Checkbox>
                Нажимая «Подтвердить заказ», вы подтверждаете, что ознакомлены с <a href="/files/policy.pdf" target="_blank">Политикой обработки и защиты персональных данных</a> и даете
                согласие на обработку своих персональных данных в соответствии с Федеральным законом от 27.07.2006 г. №152-ФЗ «О персональных данных».
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="companyConditions"
              valuePropName="checked"
              rules={agreementValidator}
              wrapperCol={{ offset: 6, span: 18 }}
            >
              <Checkbox>
                Переходя к оплате заказа, вы подтверждаете, что&nbsp;
                <a rel="noreferrer" href="/files/1.pdf" target="_blank">
                  ознакомлены с порядком возврата денежных средств и согласны с условиями возврата
                </a>.
              </Checkbox>
            </Form.Item>
          </div>
        </Space>

        <Row gutter={[16, 16]} style={{ marginTop: '24px' }}>
          <Col xs={24} sm={12}>
            <Button onClick={props.onPrevStageClick} block>Вернуться к корзине</Button>
          </Col>

          <Col xs={24} sm={12}>
            <Button type="primary" block htmlType="submit">Подтвердить заказ</Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
};

export const WrappedOrderForm: FC<OrderFormProps> = props => (
  <OrderForm {...props} />
);
