import { useEffect, useState } from "react";

const DEFAULT_MOBILE_MAX_WIDTH = 860;

/**
 * Is current viewport is mobile or not
 * @param widthThreshold - Threshold for recognize mobile width
 * @returns `true` if user viewport is less or equal than threshold
 */
const useMobileWidth = (widthThreshold: number = DEFAULT_MOBILE_MAX_WIDTH) => {
  const [windowWidth, setWindowWidth] = useState<number | null>(null);

  const getWindowWidth = () => window.innerWidth;

  useEffect(() => {
    if (windowWidth === null) {
      setWindowWidth(getWindowWidth());
    }

    const onWindowWidthChange = () => setWindowWidth(getWindowWidth());

    window.addEventListener('resize', onWindowWidthChange);

    return () => window.removeEventListener('resize', onWindowWidthChange);
  }, [windowWidth]);

  return (windowWidth || 0) <= widthThreshold;
};

export default useMobileWidth;
