import { DeleteOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Button, List, Tooltip } from "antd";
import { FC } from "react";

interface Props {
  name: string;
  cost: number;
  isServiceInBasket: boolean;
  onAdd: () => void;
  onRemove: () => void;
  desc: string;
  documentId: string;
}

const Number: FC = ({ children }) => (
  <div style={{ width: 100 }}>{children}</div>
)

export const Service: FC<Props> = (
  { name, cost, isServiceInBasket, onAdd, onRemove, desc, documentId },
) => {
  return (
    <List.Item
      actions={[
        (<Tooltip title="Добавить в корзину">
          <Button type="link" onClick={onAdd}>
            <ShoppingCartOutlined />
          </Button>
        </Tooltip>),

        !!isServiceInBasket
          ? (<Tooltip title="Удалить из корзины">
              <Button type="text" danger onClick={onRemove}>
                <DeleteOutlined />
              </Button>
            </Tooltip>)
          : (<Button type="text" disabled><DeleteOutlined /></Button>),
      ]}
    >
      <List.Item.Meta title={name} description={desc} avatar={<Number>{documentId}</Number>} />
      <div>{cost} ₽</div>
    </List.Item>
  );
};
