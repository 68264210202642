import { StoreModel } from "./model";

/** Data extractor from store */
export class StoreTransformer {
  static getItemCountInBasket = (state: StoreModel): number => state.basket.items.length;

  static getSummaryOrder = (state: StoreModel): number =>
    state.basket.items
      .map(({ serviceId }) => state.services.items.find(({ id }) => id === serviceId)?.cost ?? 0)
      .reduce((prev, curr) => prev + curr, 0);

  static hasServiceInBasket = (state: StoreModel, id: number) => state.basket.items.some(({ serviceId }) => serviceId === id);

  static getOrderData = (state: StoreModel) =>
    `${state.basket.info?.name} ${state.basket.info?.surname}, ${state.basket.info?.phone}`;
}
