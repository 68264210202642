import { StoreModel } from "data/store";

/** Each change of state will log in console on dev modew */
export class DebugMiddleware {
  private static actionNum = 1;
  static output = (state: StoreModel) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log(`state ${DebugMiddleware.actionNum}`, state);
      DebugMiddleware.actionNum++;
    }
  };
}
