import { Button, Space, Table, Typography } from "antd";
import { DeleteServiceButton } from "common/components";
import { Store, StoreActionSupervisor, StoreTransformer } from "data/store";
import { FC, PropsWithChildren, useContext } from "react";

interface BasketListProps {
  onNextStageClick: () => void;
}

export const BasketList: FC<BasketListProps> = (props: PropsWithChildren<BasketListProps>) => {
  const { state, dispatch } = useContext(Store);

  const data = state.basket.items.map(({ id, serviceId }) => {
    const service = state.services.items.find(service => service.id === serviceId);

    return {
      id,
      key: id,
      name: service?.name,
      cost: service?.cost,
    }
  });

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Table
        dataSource={data}
        pagination={false}
        scroll={{ x: 830 }}
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0}>
              <Typography.Text strong>Итого:</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={1}>
              <Typography.Text strong>{StoreTransformer.getSummaryOrder(state)} ₽</Typography.Text>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      >
        <Table.Column title="Название услуги" dataIndex="name" key="name" ellipsis />

        <Table.Column
          title="Стоимость сеанса/услуги"
          dataIndex="cost"
          key="cost"
          render={(cost: string) => `${cost} ₽`}
          ellipsis
        />

        <Table.Column
          key="count"
          render={(_: any, record: typeof data[number]) => (
            <DeleteServiceButton
              onConfirm={() => StoreActionSupervisor.removeServiceFromBasket(dispatch, record.id)}
            />
          )}
          width={120}
        />
      </Table>

      <Button type="primary" block onClick={props.onNextStageClick}>Перейти к оформлению заказа</Button>
    </Space>
  );
};
